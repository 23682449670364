<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/reviews`"
        />

        <div class="pageContent backgroundZircon writeReviewContentWrapper">
            <div class="writeReviewContent" v-if="isVisibleForm">
                <div class="writeReviewContent__header">
                    <p class="h3">
                        Ваш отзыв
                    </p>
                </div>
                <div class="form form--2">
                    <div class="login__input formgroup">
                        <label class="formgroup__label text2 colorMirage">
                            Поделитесь, пожалуйста, Вашими впечатлениями от прохождения
                            программы
                        </label>
                        <div class="suggestTheme__inputWrapper">
                            <TextArea
                                rows="12"
                                type="text"
                                placeholder="Введите текст"
                                :optimalTextLength="200"
                                optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                                :isError="isEmptyDescriptionImpression || isDescriptionImpressionLong"
                                :errorText="
                                    isEmptyDescriptionImpression
                                        ? 'Введите описание'
                                        : 'Вы не можете отправить более 1000 символов в этом текстовом поле'
                                "
                                :onChange="(event) => onChangeTextAreaImpression(event)"
                            />
                        </div>
                    </div>
                    <div class="login__input formgroup">
                        <label class="formgroup__label text2 colorMirage">
                            Что Вам понравилось в обучении?
                        </label>
                        <div class="suggestTheme__inputWrapper">
                            <TextArea
                                rows="12"
                                type="text"
                                placeholder="Введите текст"
                                :optimalTextLength="200"
                                optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                                :isError="isEmptyDescriptionLiked || isDescriptionLikedLong"
                                :errorText="
                                    isEmptyDescriptionLiked
                                        ? 'Введите описание'
                                        : 'Вы не можете отправить более 1000 символов в этом текстовом поле'
                                "
                                :onChange="(event) => onChangeTextAreaLiked(event)"
                            />
                        </div>
                    </div>
                    <div class="login__input formgroup">
                        <label class="formgroup__label text2 colorMirage">
                            А что мы можем улучшить или изменить?
                        </label>
                        <div class="suggestTheme__inputWrapper">
                            <TextArea
                                rows="12"
                                type="text"
                                placeholder="Введите текст"
                                :optimalTextLength="200"
                                optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                                :isError="isEmptyDescriptionImprove || isDescriptionImproveLong"
                                :errorText="
                                    isEmptyDescriptionImprove
                                        ? 'Введите описание'
                                        : 'Вы не можете отправить более 1000 символов в этом текстовом поле'
                                "
                                :onChange="(event) => onChangeTextAreaImprove(event)"
                            />
                        </div>
                    </div>
                    <Button
                        class="writeReviewContent__sendButton"
                        title="Отправить"
                        extraClass="button"
                        :onClick="() => sendReview()"
                    />
                </div>
            </div>

            <div class="writeReviewContent" v-if="isVisibleResult">
                <div class="writeReviewContent__reviewsSendContent">
                    <img
                        alt="infoBlue"
                        src="@/assets/img/pages/reviews/writeReview/hands.png"
                    />
                    <p class="h3 writeReviewContent__reviewsSendContent_reviewsSend">
                        Ваш отзыв отправлен
                    </p>
                    <p
                        class="text2 colorFiord writeReviewContent__reviewsSendContent_reviewsSendText"
                    >
                        Спасибо, что помогаете нам стать еще лучше. <br />
                        Вам начислено
                        <b class="colorYellow">25 баллов</b>!
                    </p>
                    <router-link
                        :to="'/' + this.$route.params.slug + '/dashboard'"
                        class="writeReviewContent__reviewsSendContent_button"
                    >
                        <Button title="На Дешборд" extraClass="button" />
                    </router-link>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

import TextArea from "@/views/components/TextArea/TextArea";
import Button from "@/views/components/Button/Button";

export default {
    name: "writeReview",
    components: {
        Layout,
        PageHeader,
        TextArea,
        Button,
    },
    props: {},

    async beforeMount() {
        if (
            !(
                this.$store.getters.getMember &&
                this.$store.getters.getMember.data &&
                this.$store.getters.getMember.data.enabled &&
                this.$store.getters.getMember.data.enabled.review
            )
        ) {
            await this.$router.push("/" + this.$route.params.slug + "/reviews");
        }
    },

    data() {
        return {
            title: "Написать отзыв",
            items: [
                {
                    text: "Отзывы",
                    to: `/${this.$route.params.slug}/reviews`,
                },
                {
                    text: "Написать отзыв",
                    active: true,
                },
            ],

            isVisibleForm: true,
            isVisibleResult: false,

            isEmptyDescriptionImpression: false,
            isDescriptionImpressionLong: false,
            isEmptyDescriptionLiked: false,
            isDescriptionLikedLong: false,
            isEmptyDescriptionImprove: false,
            isDescriptionImproveLong: false,

            impressionText: "",
            likedText: "",
            improveText: ""
        };
    },
    methods: {
        viewResult() {
            this.isVisibleForm = false;
            this.isVisibleResult = true;
        },

        onChangeTextAreaImpression(event) {
            this.impressionText = event;
        },

        onChangeTextAreaLiked(event) {
            this.likedText = event;
        },

        onChangeTextAreaImprove(event) {
            this.improveText = event;
        },

        sendReview: async function() {
            this.isEmptyDescriptionImpression = !this.impressionText.length;
            this.isEmptyDescriptionLiked = !this.likedText.length;
            this.isEmptyDescriptionImprove = !this.improveText.length;

            this.isDescriptionImpressionLong = this.impressionText.length > 1000;
            this.isDescriptionLikedLong = this.likedText.length > 1000;
            this.isDescriptionImproveLong = this.improveText.length > 1000;

            if (this.isEmptyDescriptionImpression || this.isEmptyDescriptionLiked || this.isEmptyDescriptionImprove) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Пожалуйста заполните все поля",
                    },
                });
            } else if (this.isDescriptionImpressionLong || this.isDescriptionLikedLong || this.isDescriptionImproveLong) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Вы не можете отправить более 1000 символов в текстовом поле",
                    },
                });
            } else {
                const reviewsBody = {
                    text: this.impressionText,
                    text2: this.likedText,
                    text3: this.improveText,
                };

                const response = await this.axios.post(
                    `/programs/${this.$route.params.slug}/reviews/new`,
                    reviewsBody
                );

                if (response && response.data && response.data.result) {
                    this.viewResult();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text: "Ваш отзыв опубликован, мы благодарны Вам.",
                        },
                    });
                } else {
                    this.viewResult();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "К сожалению Ваш отзыв не удалось опубликовать.",
                        },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
@import "./writeReview.scss";
</style>
